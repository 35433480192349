<template>
  <v-container class="fill-height" fluid>
    <app-loader />
    <v-row class="pt-12" no-gutters align="center" justify="center">
      <img :src="appLogo" height="310" alt="logo" />
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <router-view class="page-wrapper" />
    </v-row>
    <v-row no-gutters align="center" justify="center">
      {{ $t('views.constants.copyright', {currentYear: moment(new Date()).year()}) }}
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';

export default {
  name: 'Session',
  data() {
    return {
      moment
    };
  },
  computed: {
    ...mapGetters(['company', 'accessToken', 'currentUser']),
    appLogo() {
      return this.company.logo ? this.getFileUrl(this.company.logo) : `/images/${process.env.VUE_APP_LOGO}`;
    }
  },
  methods: mapActions(['getCompany']),
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getCompany().then(() => {
        if (vm._.isNil(vm.company.id)) {
          if (to.name === 'Register' || to.name === 'RegisterSuccess') {
            next();
          } else {
            next('/register');
          }
        } else {
          if (vm._.isEmpty(vm.accessToken) && vm._.isNil(vm.currentUser.id)) {
            if (to.name === 'Login' || to.name === 'ResetPassword') {
              next();
            } else {
              next('/login');
            }
          } else if (vm._.isEmpty(vm.accessToken) && !vm._.isNil(vm.currentUser.id)) {
            if (to.name === 'SetPassword') {
              next();
            } else {
              next('/set-password');
            }
          }
        }
      });
    });
  }
};
</script>
